import { lazy, Suspense, useEffect, useState } from "react";
import Loader from "./components/Loader";
import { useScript } from './utils';

const Header = lazy(() => import("./components/Header"));
const PageContent = lazy(() => import("./components/PageContent"));
const Footer = lazy(() => import("./components/Footer"));

function App() {
  useScript('scripts/bootstrap.bundle.min.js?ver=1.2.0');
  useScript('scripts/aos.js?ver=1.2.0');
  useScript('scripts/main.js?ver=1.2.0');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const _res = await fetch("/data.json");
      const _data = await _res.json();
      setData(_data);
    } catch (error) {
      alert("Unexpected Error occurred. Please try after sometime");
      console.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  console.log(data);
  return (
    <Suspense fallback={<Loader />}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header data={data} />
          <PageContent data={data} />
          <Footer data={data} />
        </>
      )}
    </Suspense>
  );
}

export default App;
